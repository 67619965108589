import React from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import { Box } from 'theme-ui'
import Seo from '../components/Seo'
import BlueElephant from "../images/logos/factcil_icon.png"

const TitleText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: bold;
`

const SubTitleText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
`

const DotText = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-weight: bold;
`

const ItemTitleText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  font-weight: bold;
`

const ItemText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  font-weight: 400;
`

const ListItemText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  margin-left: 2rem;
  font-weight: 400;
`

const SignatureText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 0px;
  text-align: left;
`

const privacyItems = [
  {
    type: 'ItemText',
    content: <><b><u>OBJETO:</u></b> FACTCIL S.A.S., (En adelante FACTCIL), dando cumplimiento a la legislación colombiana que regula la materia y, particularmente a lo dispuesto en el artículo 15 de la Constitución Política de Colombia que consagra la protección de datos personales y los define como el derecho fundamental que tienen todas las personas a conservar su intimidad personal y familiar y su buen nombre, lo mismo que conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellos en bancos de datos y en archivos de las entidades públicas y privadas, así como lo dispuesto en la Ley 1581 de 2012, el Decreto 1377 de 2013, y las demás normas que los complementen o adicionen, establece los criterios para la recolección, almacenamiento, uso, circulación y supresión de los datos personales que se hayan recogido o son tratados en las bases de datos o archivos de propiedad de FACTCIL para el cumplimiento de sus fines, la cual puede ser consultada por usted, así como solicitar su rectificación, actualización, eliminación, o una copia de la prueba de su autorización.</>
  },
  {
    type: 'ItemText',
    content: <><b><u>DEFINICIONES:</u></b> Con el propósito de facilitar la comprensión de la política se deben tener en cuenta las siguientes definiciones:</>
  },
  {
    type: 'ListItemText',
    content: <>
      -	<u>Tratamiento de datos o de información:</u> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.<br />
      -	<u>Responsable del Tratamiento:</u> Es toda persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, tenga poder de decisión sobre las bases de datos y/o el Tratamiento de los datos, entendiendo por tratamiento “Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión”. El responsable del tratamiento tiene entre sus actividades las de definir la finalidad y la forma en que se almacenan, recolectan y administran los datos y solicitar y conservar la autorización en la que conste el consentimiento expreso del titular de la información.<br />
      -	<u>Encargado del Tratamiento:</u> Es la persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros realiza el tratamiento de datos personales en virtud de la delegación o mandato por parte del Responsable. Entre dichos encargos se encuentran la obtención de autorizaciones por parte de los ciudadanos y la verificación de cumplimiento de la finalidad en la recolección por parte de la Entidad.<br />
      -	<u>Autorizaciones:</u> Es el consentimiento dado por el dueño o titular de información que se pretende incluir en una base de datos, dicha autorización debe informarle cuáles datos personales serán recolectados, así como las finalidades para las cuales será usado el dato.<br />
      -	<u>Aviso de Privacidad:</u> Es el instrumento a través del cual se le comunica al titular de la información al momento de la recolección de los datos personales, que se cuenta con las políticas de Tratamiento de información que le serán aplicables y que incluye como mínimo: a) Los datos del responsable del tratamiento, b) Los derechos del titular y c) Los canales dispuestos para que el titular conozca la política de tratamiento de datos personales.<br />
      -	<u>Base de datos o Banco de Datos:</u> Se entiende como el conjunto de datos personales pertenecientes a un mismo contexto y almacenados sistemáticamente para su posterior uso.<br />
      -	<u>Datos personales:</u> Hace referencia a cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables; pueden ser clasificados en cuatro grandes categorías: públicos, semiprivados, privados y sensibles.<br />
      -	<u>Datos públicos:</u> Son todos aquellos que no son de naturaleza semiprivada o privada, como también los contenidos en documentos públicos, sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva, y los relativos al estado civil de las personas. Entre los datos de naturaleza pública a resaltar se encuentran: los registros civiles de nacimiento, matrimonio y defunción, y las cédulas de ciudadanía apreciadas de manera individual y sin estar vinculadas a otro tipo de información.<br />
      -	<u>Dato semiprivado:</u> Es aquella información que no es de naturaleza íntima, reservada ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de personas o a la sociedad en general, como es el caso de los datos financieros, crediticios o actividades comerciales.<br />
      -	<u>Dato privado:</u> Es la información de naturaleza íntima o reservada que, por encontrarse en un ámbito privado, sólo puede ser obtenida y ofrecida por orden de autoridad judicial en el cumplimiento de sus funciones, así como por decisión del titular de los mismos. Es el caso de los libros de los comerciantes, de los documentos privados, de las historias clínicas o de la información extraída a partir de la inspección del domicilio.<br />
      -	<u>Datos sensibles:</u> Es la información que afecta la intimidad del titular o cuyo uso indebido puede generar su discriminación, tal es el caso del origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos.<br />
      -	<u>Transferencia:</u> La transferencia de datos tiene lugar cuando el Responsable y/o encargado del tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.<br />
      -	<u>Transmisión:</u> Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un tratamiento por el Encargado por cuenta del Responsable.<br />
    </>
  },
  {
    type: 'ItemText',
    content: <><b><u>RESPONSABLE DEL TRATAMIENTO:</u></b> FACTCIL, actúa como responsable del tratamiento de sus datos personales y hará uso de los mismos de conformidad con el objeto y finalidad descritos en esta Política. Los datos del responsable del tratamiento son los siguientes:</>
  },
  {
    type: 'TableData',
    content: [<>
      Entidad: <br />
      Domicilio: <br />
      NIT: <br />
      Matrícula mercantil: <br />
      Teléfono: <br />
      Correo electrónico: <br />
      Horario de atención: <br /></>, <>
      Factcil S.A.S. <br />
      Carrera 19B No. 83-49 PISO 4, Bogotá D.C – Colombia. <br />
      901.286.510-0 <br />
      3112663 <br />
      (+57) (1) 315 873 1305 <br />
      latam-datospersonales@factcil.com <br />
      lunes a viernes de 8:00 a.m. a 5:00 p.m. <br /></>]
  },
  {
    type: 'ItemText',
    content: <><b><u>DESTINATARIOS:</u></b> Esta Política se aplicará a todas las Bases de Datos tanto físicas como digitales que contengan datos que sean objeto de Tratamiento por parte de FACTCIL. Esta política es de obligatorio conocimiento y cumplimiento por todos para todas las personas naturales o jurídicas responsables de la administración de bases de datos personales de FACTCIL, en especial los administradores del manejo de bases de datos y por aquellos funcionarios y contratistas que reciben, atienden y dan respuesta directa o indirectamente a las peticiones, consultas o reclamos de información relacionadas con la ley de protección de datos personales.<br /><br />
      FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES El tratamiento que realizará FACTCIL a los datos, tendrá exclusivamente las siguientes finalidades:</>
  },
  {
    type: 'ListItemText',
    content: <>
      1.	Administrar y gestionar los servicios ofrecidos por FACTCIL en los cuales el Titular decida registrarse.<br />
      2.	Administrar la información de los funcionarios, contratistas, terceros y visitantes para el cumplimiento de los fines de FACTCIL.<br />
      3.	Analizar, evaluar y generar datos estadísticos e indicadores.<br />
      4.	Realizar ante los operadores de información (Datacrédito, Cifin y/o cualquier otra entidad que llegue a manejar bases de datos con los mismos objetivos o relacionada con pagos de seguridad social) la consulta de tu información crediticia<br />
      5.	Realizar el reporte de la generación, modificación, extinción, cumplimiento o incumplimiento de las obligaciones contraídas FACTCIL y la utilización indebida de los productos y/o servicios prestados u ofrecidos ante los operadores de información.<br />
      6.	Compartir e intercambiar con sus entidades filiales, matrices, subordinadas y/o aliadas, tu información personal contenida en las bases de datos de FACTCILcon fines de control de riesgos, desembolso y pago de obligaciones, comerciales, estadísticos y la realización de actividades de mercadeo de sus servicios y publicidad.<br />
      7.	Adelantar contactos con fines comerciales y promocionales ya sea sobre sus propios servicios y productos, o los de terceros con los que FACTCIL tenga relaciones comerciales o alianzas, a través de correo, teléfono, celular, correo electrónico o cualquier otro medio conocido o por conocer.<br />
      8.	Actualizar tu información a través de diferentes actividades como lo son la consulta en bases de datos públicas, páginas de internet y redes sociales y referencias de terceras personas.<br />
      9.	Cumplir con el deber de acceso a la información contenido en la Ley 1712 de 2014, ley de transparencia y acceso a la información o las que las modifiquen o sustituyan.<br />
      10.	Transmitir los datos personales fuera del país a terceros con los cuales FACTCIL haya suscrito un contrato de procesamiento de datos y sea necesario entregarlos para el cumplimiento del objeto contractual.<br />
      11.	Suministrar información a terceros con los cuales FACTCIL tenga relación contractual y sea necesario entregarla para el cumplimiento del objeto contratado.<br />
      12.	Informar los cambios que se puedan llegar a presentar en desarrollo de los vínculos comerciales.<br />
      13.	Realizar estudios internos sobre los hábitos de los empleados, colaboradores, accionistas, clientes y proveedores.<br />
      14.	Notificarle sobre nuevos servicios que ofrezca la sociedad.<br />
      15.	Realizar evaluaciones de nuestros servicios a efecto de mejorar la calidad de los mismos.
    </>
  },
  {
    type: 'ItemText',
    content: 'DERECHOS DE LOS TITULARES: La Ley 1581 de 2012 establece que los Titulares de los datos personales tendrán los siguientes derechos:'
  },
  {
    type: 'ListItemText',
    content: <>
      1.	Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado y <br />
      2.	Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos personales.

    </>
  },
  {
    type: 'ItemText',
    content: <><b><u>ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS:</u></b> El titular de los datos tiene los siguientes medios para hacer peticiones, consultas, quejas y reclamos:</>
  },
  {
    type: 'TableData',
    content: [<>
      Correo electrónico:  <br />
      Comunicación escrita: : <br /></>, <>
      latam-datospersonales@factcil.com <br />
      Carrera 19B No. 83-49 PISO 4, Bogotá D.C – Colombia.<br /></>]
  },
  {
    type: 'ItemText',
    content: 'Para la radicación y atención de una solicitud solicitamos suministrar la siguiente información:'
  },
  {
    type: 'ListItemText',
    content: <>
      1.	Nombre completo y apellidos.<br />
      2.	Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto).<br />
      3.	Medio elegido para recibir respuesta a su solicitud.<br />
      4.	Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información).<br />
      5.	Firma (si aplica) y <br />
      6.	Número de identificación. <br />
    </>
  },
  {
    type: 'ItemText',
    content: 'El término máximo previsto en la ley para resolver su reclamación es de quince (15) días hábiles, contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, FACTCIL informará al interesado los motivos de la demora y la fecha en la que se atenderá su reclamo, la cual, en ningún caso, podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. En caso de que FACTCIL no sea competente para resolverlo, dará traslado a quien corresponda en un término máximo de 2 días hábiles e informará de la situación al interesado.'
  },
  {
    type: 'ItemText',
    content: <><b><u>RESPONSABILIDAD DE FACTCIL:</u></b> FACTCIL no será responsable por acciones de carácter informático que tengan como objetivo infringir las medidas de seguridad implementadas, puesto que al realizar operaciones a través de internet se pueden presentar algunas de las siguientes situaciones:</>
  },
  {
    type: 'ListItemText',
    content: <>
      -	Que el usuario sea remitido mediante engaños a una página web que simula ser la FACTCIL, para obtener su información personal al diligenciar el formulario de contáctenos.<br />
      -	Que el usuario tenga instalado en su equipo software mal intencionado que capture información que sea enviada a terceros sin autorización del titular.<br />
      -	Que el usuario sea suplantado por un tercero con la intención de acceder a la información personal de este.
    </>
  },
  {
    type: 'ItemText',
    content: 'Si quien suministra información personal, lo hace sobre una persona distinta, damos por entendido que cuenta con la autorización de esa persona para suministrar sus datos a FACTCIL, por lo cual, no asumimos responsabilidad por los daños o perjuicios de toda naturaleza que pudiera tener origen en la falta de veracidad, homonimia o a la suplantación de la información de la identidad, de los cuales será quien suministró la información el único responsable ante el titular de la información.'
  },
  {
    type: 'ItemText',
    content: <><b><u>DERECHO DE LOS NIÑOS, NIÑAS Y ADOLESCENTES:</u></b> En el Tratamiento se asegurará el respeto a los derechos de los niños, niñas y adolescentes. Sólo podrán tratarse aquellos datos que sean de naturaleza pública. El tratamiento de datos personales de niños, niñas y adolescentes está prohibido, excepto cuando se trate de datos de naturaleza pública, y cuando dicho tratamiento cumpla con los siguientes parámetros y/o requisitos:</>
  },
  {
    type: 'ListItemText',
    content: <>1.	Que respondan y respeten el interés superior de los niños, niñas y adolescentes y<br />
      2.	Que se asegure el respeto de sus derechos fundamentales.
    </>
  },
  {
    type: 'ItemText',
    content: 'Cumplidos los anteriores requisitos, el representante legal de los niños, niñas o adolescentes otorgará la autorización, previo ejercicio del menor de su derecho a ser escuchado, opinión que será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el asunto.'
  },
  {
    type: 'ItemText',
    content: <><b><u>AUTORIZACIÓN PARA REALIZAR EL TRATAMIENTO:</u></b> FACTCIL solicitará autorización previa, expresa e informada a los Titulares de los Datos Personales sobre los que requiera realizar el Tratamiento. Esta manifestación de voluntad del Titular puede darse a través de diferentes mecanismos puestos a disposición por FACTCIL, tales como:</>
  },
  {
    type: 'ListItemText',
    content: <>
      1.	Por escrito, diligenciando un formato de autorización para el Tratamiento de Datos Personales determinado por FACTCIL.<br />
      2.	De forma oral, a través de una conversación telefónica o en videoconferencia.<br />
      3.	Mediante el “click” que haga aceptando la política publicada y<br />
      4.	Mediante conductas inequívocas que permitan concluir que otorgó su autorización.

    </>
  },
  {
    type: 'ItemText',
    content: 'Con esta autorización, manifiesta que conoce, acepta y autoriza de forma libre y espontánea, para que este pueda, como ya se mencionó, compilar, almacenar, consultar, usar, compartir, intercambiar, transmitir y transferir los datos personales suministrados.'
  },
  {
    type: 'ItemText',
    content: <><b><u>SEGURIDAD DE LOS DATOS PERSONALES:</u></b> FACTCIL proporcionará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. La obligación y responsabilidad de FACTCIL se limita a disponer de los medios adecuados para este fin. FACTCIL no garantiza la seguridad total de su información ni se responsabiliza por cualquier consecuencia derivada de fallas técnicas o del ingreso indebido por parte de terceros a la Base de Datos o archivo en los que reposan los Datos Personales objeto de Tratamiento por parte de FACTCIL y sus Encargados.<br /><br />
      De igual manera le informamos que usted, como titular de su información personal, puede presentar alguna queja ante la Superintendencia de Industria y Comercio por violación a Régimen de Protección de Datos, haciendo uso de los mecanismos dispuestos por esa Entidad para el efecto.<br /><br />
      <b><u>ENTRADA EN VIGENCIA:</u></b> La presente Política de Tratamiento y Protección de Datos Personales fue publicada en el sitio web de FACTCIL el 17 de Mayo de 2022, y entra en vigencia a partir de la fecha de publicación.<br /><br />
      La vigencia de las Bases de Datos será el tiempo razonable y necesario para cumplir las finalidades del Tratamiento.
    </>
  }
]

const PrivacyPolicyPage = () => (
  <React.Fragment>
    <Seo title='Política de privacidad' />
    <Box
      sx={{
        textAlign: 'left',
        p: [15, 30],
        maxWidth: 1200,
        margin: '0 auto',
      }}
    >
      <TitleText as="h1" >
        Política de tratamiento y proteccion de datos personales<DotText>.</DotText>
      </TitleText>
      {privacyItems.map((item) => {
        if (item.type === 'SubTitleText') {
          return <>
            <SubTitleText as="h5" >
              {item.content}
            </SubTitleText></>
        }
        if (item.type === 'ItemTitleText') {
          return <>
            <ItemTitleText as="h5" >
              {item.content}
            </ItemTitleText></>
        }
        if (item.type === 'ItemText') {
          return <>
            <ItemText as="h6" >
              {item.content}
            </ItemText></>
        }
        if (item.type === 'ListItemText') {
          return <>
            <ListItemText as="h6" >
              {item.content}
            </ListItemText></>
        }
        if (item.type === 'TableData') {
          return <>
            <Table>
              <tr>
                <td>
                  <ItemText as="h6">
                    {item.content[0]}
                  </ItemText>
                </td>
                <td>
                  <ItemText as="h6">
                    {item.content[1]}
                  </ItemText>
                </td>
              </tr>
            </Table>
          </>
        }
      })}
      <div className='mt-5 container'>
        <div className='row d-flex justify-content-between'>
          <div className='container p-0 d-flex justify-content-start col-sm-12 col-md-6'>
            <div className='row'>
              <div className='col-6 p-0'>
                {<img src={BlueElephant} alt="blue-elephant" style={{ maxWidth: '4rem' }} />}
              </div>
              <div className='col-6 p-0 align-self-center'>
                <div className='col-12 p-0'>
                  Atentamente
                </div>
                <div className='col-12 p-0 '>
                  <SignatureText as="h6" >
                    Factcil
                  </SignatureText>
                </div>
              </div>
            </div>
          </div>
          <div className='container p-0 d-flex justify-content-end col-sm-12 col-md-6'>
            <div className='row'>
              <div className='col-12 p-0' style={{ textAlign: 'end' }}>
                Última actualización
              </div>
              <div className='col-12 p-0'>
                <SignatureText as="h6" style={{ textAlign: 'end' }}>
                  17 de mayo de 2022
                </SignatureText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </React.Fragment>
)

export default PrivacyPolicyPage
